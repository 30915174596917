import React from "react";
import { Api } from "./Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
//For ui design
import { TextField as Tfield } from "formik-mui";
import { Avatar, SpeedDialIcon, Typography } from "@mui/material";

import {
  FormControl,
  Box,
  Grid,
  Button,
  Link,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// For Date
import "react-datepicker/dist/react-datepicker.css";
// For Modal popup
import "bootstrap/dist/css/bootstrap.css";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

// To navigate to Login Page

const theme = createTheme();
export function AddEvent() {
  // For Terms & Condition Modal popup
  const cookies = new Cookies();
  // To navigate to Login Page
  const initialValues = {
    organiser: cookies.get("uid"),
    name: "",
    max_accomodation: 0,
    country: "",
    city: "",
    address: "",
    zipcode: "",
    pricingVIP: "",
    pricingEconomy: "",
    pricingPlatinum: "",
    discounts: [20, 30],
    description: "",
    date: Date.now(),
    status: false,
    parking: false,
    pets: false,
  };
  const onSubmit = (values, onSubmitProps) => {
    
    Api.post("/event", values)
    console.log(values)

  };

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <PageFrontDesign />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handlePopup, status }) => (
              <div className="auth-form-container">
                <Form className="add-event-form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        name="name"
                        label={t("event_name")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        component="textarea"
                        name="description"
                        label="Event description"
                        placeholder={t("event_description")}
                        rows="4"
                        cols="50"
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        name="country"
                        label={t("country")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        name="city"
                        label={t("city")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        name="max_accomodation"
                        label={t("max_accomodation")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl variant="standard">
                          <Field
                            component={Tfield}
                            name="date"
                            id="date"
                            fullWidth
                            label={t("event_date")}
                            type="date"
                            disabled={false}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        validate="[0-9]*"
                        name="pricingEconomy"
                        label={t("economy_pricing")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        validate="[0-9]*"
                        name="pricingVIP"
                        label={t("vip_pricing")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        fullWidth
                        name="pricingPlatinum"
                        label={t("platinum_pricing")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        fullWidth
                        component={Tfield}
                        name="address"
                        label={t("address")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        name="zipcode"
                        id="zipcode"
                        label={t("zipcode")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <label htmlFor="status">{t("event_status")}</label>
                      <Field name="status" type="checkbox" />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="parking">{t("event_parking")}</label>
                      <Field name="parking" type="checkbox" />
                      <ErrorMessage
                        name="parking"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="pets">{t("event_pet")}</label>
                      <Field name="pets" type="checkbox" />
                      <ErrorMessage
                        name="pets"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="invite_only">{t("event_invitation")}</label>
                      <Field name="invite_only" type="checkbox" />
                      <ErrorMessage
                        name="invite_only"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Button type="submit" variant="contained" fullWidth>
                    {t("submit")}
                    </Button>
                  </Grid>
                  <p></p>
                  <div className="form-success">
                    {status ? status.success : ""}
                  </div>
                  <div className="form-error">{status ? status.error : ""}</div>
                </Form>
              </div>
            )}
          </Formik>
        </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </ThemeProvider>
  );
}

function PageFrontDesign() {

  const { t } = useTranslation();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <SpeedDialIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
      {t("create_event")}
      </Typography>
    </Box>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Event.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
