import React, {useState} from "react";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Api } from "./Api";
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Event.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
const theme = createTheme();

export const ForgotPassword = (props) => 
{
    const[email, setEmail] = useState('');
    const[oldPass, setOldPass] = useState('');
    const[newPass, setNewPass] = useState('');
    
    const handleSubmit =(e) => {
        const UserEntry = {email:email, password:oldPass, newPassword:newPass};
        console.log(UserEntry)
        Api.post("/forgotPassword", { UserEntry })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.log(error.message);
                });
        e.preventDefault(); // prevent form refresh
        setEmail("");
        setOldPass("");
        setNewPass("");
    };

    return(
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="youremail@gmail.com" 
              id="email" 
              name="email" 
              autoComplete="off" 
              required={true}
              autoFocus
              margin="normal"
              fullWidth
              label="Email Address"
            />
            <TextField
              value={oldPass} 
              onChange={(e) => setOldPass(e.target.value)} 
              type="password"
              placeholder="********" 
              id="password" 
              name="password" 
              autoComplete="off" 
              required={true}
              margin="normal"
              fullWidth
              label="Old Password"
            />
            <TextField
              value={newPass} 
              onChange={(e) => setNewPass(e.target.value)} 
              type="password"
              placeholder="********" 
              id="password" 
              name="password" 
              autoComplete="off" 
              required={true}
              margin="normal"
              fullWidth
              label="New Password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change password
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    );
}