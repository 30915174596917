import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import { Paper, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ListOfEvents } from "../ListOfEvents";
import { useTranslation } from "react-i18next";

const data = [
  {
    name: "Dec `22",
    rating: 5,
    price: 3000,
  },
  {
    name: "Jan `23",
    rating: 4,
    price: 2400,
  },
  {
    name: "Feb `23",
    rating: 4.5,
    price: 2210,
  },
  {
    name: "Mar `23",
    rating: 5,
    price: 2290,
  },
  {
    name: "Apr `23",
    rating: 3,
    price: 2181,
  },
];

function Datatest() {
  return (
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line dataKey="price" fill="#8884d8" />
    </LineChart>
  );
}
function Datatest1() {
  return (
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="rating" fill="#8884d8" />
    </BarChart>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function DashApp() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Item elevation={3}>
          <ListOfEvents />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item elevation={3}>
          <h3>{t("ticket_graph")}</h3>
          <Datatest />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item elevation={3}>
          <h3>{t("review_graph")} </h3>
          <Datatest1 />
        </Item>
      </Grid>
    </Grid>
  );
}
