import React from "react";
import { Api } from "./Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import BrushIcon from "@mui/icons-material/Brush";
import { TextField as Tfield } from "formik-mui";
import { Avatar, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { FormControl, Box, Grid, Button, Link, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "react-datepicker/dist/react-datepicker.css";

import "bootstrap/dist/css/bootstrap.css";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const theme = createTheme();

export function EditEvent() {
  const onStart = (id) => {
    return Api.get("/eventById?id=" + id)
  };

  const { state } = useLocation();
  const cookies = new Cookies();
  const event = onStart(state);
  const initialValues = {
    _id: state,
    organiser: cookies.get("uid"),
    name: "",
    max_accomodation: 0,
    country: "",
    city: "",
    address: "",
    zipcode: "",
    pricingVIP: "",
    pricingEconomy: "",
    pricingPlatinum: "",
    discounts: [20, 30],
    description: "",
    date: Date.now(),
    status: false,
    parking: false,
    pets: false,
  };
  
  event.then((d) => {
    initialValues.name = d.data.name;
    initialValues.country = d.data.country;
    initialValues.city = d.data.city;
    initialValues.address= d.data.address;
    initialValues.zipcode= d.data.zipcode;
    initialValues.description= d.data.description;
    initialValues.pricingVIP= d.data.pricingVIP;
    initialValues.pricingEconomy= d.data.pricingEconomy;
    initialValues.pricingPlatinum= d.data.pricingPlatinum;
    initialValues.date= d.data.date;
    initialValues.status= d.data.status;
    initialValues.max_accomodation= d.data.max_accomodation;
    initialValues.parking= d.data.parking;
    initialValues.pets= d.data.pets;
  })
  const onSubmit = (values, onSubmitProps) => {
    values.id = state
    Api.patch("/event", values).then((res) => {
      console.log(res);
    });
    console.log(values);
  };
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <PageFrontDesign />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, handlePopup, status }) => (
              <div className="auth-form-container">
                <Form className="add-event-form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        name="name"
                        label={t("event_name")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        component="textarea"
                        name="description"
                        label="Event description"
                        placeholder={t("event_description")}
                        rows="4"
                        cols="50"
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        name="country"
                        label={t("country")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        name="city"
                        label={t("city")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        name="max_accomodation"
                        label={t("max_accomodation")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl variant="standard">
                          <Field
                            component={Tfield}
                            name="date"
                            id="date"
                            fullWidth
                            label={t("event_date")}
                            type="date"
                            disabled={false}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        validate="[0-9]*"
                        name="pricingEconomy"
                        label={t("economy_pricing")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        fullWidth
                        validate="[0-9]*"
                        name="pricingVIP"
                        label={t("vip_pricing")}
                        disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        fullWidth
                        name="pricingPlatinum"
                        label={t("platinum_pricing")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        fullWidth
                        component={Tfield}
                        name="address"
                        label={t("address")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={Tfield}
                        validate="[0-9]*"
                        name="zipcode"
                        id="zipcode"
                        label={t("zipcode")}
                        disabled={false}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <label htmlFor="status">{t("event_status")}</label>
                      <Field name="status" type="checkbox" />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="parking">{t("event_parking")}</label>
                      <Field name="parking" type="checkbox" />
                      <ErrorMessage
                        name="parking"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="pets">{t("event_pet")}</label>
                      <Field name="pets" type="checkbox" />
                      <ErrorMessage
                        name="pets"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor="invite_only">{t("event_invitation")}</label>
                      <Field name="invite_only" type="checkbox" />
                      <ErrorMessage
                        name="invite_only"
                        component="div"
                        className="form-error"
                      ></ErrorMessage>
                    </Grid>
                    <Button type="submit" variant="contained" fullWidth>
                    {t("submit")}
                    </Button>
                  </Grid>
                  <p></p>
                  <div className="form-success">
                    {status ? status.success : ""}
                  </div>
                  <div className="form-error">{status ? status.error : ""}</div>
                </Form>
              </div>
            )}
          </Formik>
        </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </ThemeProvider>
  );
}

function PageFrontDesign() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <BrushIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
      {t("edit_event")}
      </Typography>
    </Box>
  );
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Event.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
