import React from "react";
import { useState } from "react";
import { Api } from "./Api";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
//For ui design 
import { TextField as Tfeild, Select} from "formik-mui";
import { Avatar, Typography} from "@mui/material";
import {MenuItem,FormControl,Box,Grid,Button,Link,Container} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// For Date 
import "react-datepicker/dist/react-datepicker.css";  
// For Modal popup
import 'bootstrap/dist/css/bootstrap.css';
import {Modal} from 'react-bootstrap';
// To navigate to Login Page
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const theme = createTheme();

export function Register() {
// For Terms & Condition Modal popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

// For Success Modal popup
  const [SuccessShow, setSuccessShow] = useState(false);
  const handleSuccessClose = () => setSuccessShow(false);
  const handleSuccessShow = () => setSuccessShow(true);

// To navigate to Login Page
  const navigate = useNavigate();
const RegistrationValidationSchema = Yup.object().shape({
    fName: Yup.string() 
     .min(2, 'Too Short!')
     .max(70, 'Too Long!')
     .required('Please enter your First name'),
     lName: Yup.string()
     .min(2, 'Too Short!')
     .max(70, 'Too Long!')
     .required('Please enter your Last name'),
     gender:Yup.number().min(1,"Please select Gender").max(2).required("Please select your Gender"),
     DOB:Yup.date().max(new Date(Date.now() - 567648000000), "You must be at least 18 years").required("Please select your Date Of Birth"),
     age:Yup.number(),
     phoneNumber:Yup.number().required("Please enter your Phone number"),
     email:Yup.string().email("Please enter valid Email").required("Please enter your Email"),
     password:Yup.string().min(6,"Password must be at least 6 characters.").required("Please enter Password"),
     is_student:Yup.bool(),
     is_phyChallenged:Yup.boolean(),
     is_signUp:Yup.boolean(),
     is_agree:Yup.boolean().oneOf([true],"Please select Agree with Terms and Coditions").required("Please select Agree with Terms and Coditions")
 });

const initialValues={
      fName: '',
      lName:'',
      gender:'0',
      DOB:'',
      age:'0',
      phoneNumber:'',
      email:'',
      password:'',
      is_student:false,
      is_phyChallenged:false,
      is_signUp:false,
      is_agree:false
 }

 function calculate_age (dt_DOB) {
    const birthDate = new Date(dt_DOB); 
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);
    return Math.abs(age.getUTCFullYear() - 1970);
  }
 
  const onSubmit= (values, onSubmitProps) => {
  
    var age=calculate_age(values.DOB);
    values.age=age;
   
    // Check email AKA User name already available in system
    Api.post('/checkEmailAvailability', {values}).then(response => 
      { console.log("Received from DB--"+response.data);
        if(response.data)
        {
          console.log("Email available");
          onSubmitProps.setStatus({error:"Email already used in the system. Try to Login"});
        }
        else
        {
          //Register user if email is not available in DB
          Api.post('./registerUser',{values}).then(response => 
                      {
                          console.log(response.data);
                          // Reset form fields after submit  
                          onSubmitProps.setSubmitting(false);
                          onSubmitProps.resetForm();
                          // Show Success message
                          //onSubmitProps.setStatus({success:response.data});
                          handleSuccessShow();
                          // After sometime clear Success message
                          setTimeout(function(){navigate("/Login"); }, 4000); 
                        }
                )
          .catch(error => {
                        console.log(error.message)
                        onSubmitProps.setStatus({error:error.message});}
                )
        }
       })
      .catch(error => 
      {
        console.log(error.message)
        onSubmitProps.setStatus({error:error.message});
      })
  }

const { t } = useTranslation();

return(
<ThemeProvider theme={theme}>
  <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center", }}>
      <PageFrontDesign/>
      <Formik
        initialValues={initialValues}
        validationSchema={RegistrationValidationSchema}
        onSubmit={onSubmit} >

       {({ values,setFieldValue,handlePopup,status }) => (
          <div className="auth-form-container">
              <Form className='register-form'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                   <Field component={Tfeild} name="fName" label={t('firstname')} disabled ={false}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field component={Tfeild} name="lName" label={t('lastname')} disabled ={false}  /> 
                </Grid>

                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} >
                        <Field
                          component={Tfeild}
                          name="DOB" id="date" label={t('dob')} type="date" disabled ={false}
                          InputLabelProps={{ shrink: true, }}
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                      <Field
                        component={Select}
                        name="gender" id="gender" labelId="gender-simple" label={t('gender')} disabled ={false}
                      >
                        <MenuItem value={0}>-{t('select')}-</MenuItem>
                        <MenuItem value={1}>{t('male')}</MenuItem>
                        <MenuItem value={2}>{t('female')}</MenuItem>
                        <MenuItem value={2}>{t('other')}</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  
                <Grid item xs={12}>
                <Field
                      component={Tfeild} fullWidth
                      name="phoneNumber" label={t('phone')} variant="outlined" disabled ={false}
                />
                </Grid>
                
                <Grid item xs={12}>
                <Field
                      component={Tfeild} fullWidth
                      name="email" id="email" label={t('email')} disabled ={false}
                    />
                </Grid>

                <Grid item xs={12}>
                <Field
                      component={Tfeild} fullWidth
                      name="password" label={t('password')} type="password" id="password" disabled ={false}
                    />
                </Grid>

                <Grid item xs={12}>
                <label htmlFor="is_student">{t('are_you_student')}?</label>
                <Field name="is_student" type="checkbox"/>
                <ErrorMessage name='is_student' component="div" className="form-error"></ErrorMessage>
    
                <label htmlFor="is_phyChallenged">{t('physical_disabilities')}?</label>
                <Field name="is_phyChallenged" type="checkbox"/>
                <ErrorMessage name='is_phyChallenged' component="div" className="form-error"></ErrorMessage>
    
                <label htmlFor="is_signUp">{t('sign_up_advertisements')}?</label>
                <Field name="is_signUp" type="checkbox"/>
                <ErrorMessage name='is_signUp' component="div" className="form-error"></ErrorMessage>
    
                <label htmlFor="is_agree">{t('agree_with')}  </label>
                <link rel="stylesheet" href="styles.css"></link>
                <Link className="link-btn" type="button" onClick={handleShow}> {t('term_agreement')}</Link>
                <Field name="is_agree" type="checkbox"/>
                <ErrorMessage name='is_agree' component="div" className="form-error"></ErrorMessage>
                </Grid>
                {/* Success Modal */}
                <Modal name="Successpopup" show={SuccessShow} onHide={handleSuccessClose}>
                  <Modal.Header>{t('registration')}</Modal.Header>
                  <Modal.Body>
                    <div>
                      {t('thankyou_registration')}
                    </div>      
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleSuccessClose}>Close</Button>
                  </Modal.Footer>
                </Modal>
                {/* Terms & Conditions Modal */}
                <Modal name="popup" show={show} onHide={handleClose}>
                  <Modal.Header>{t('term_condition')}</Modal.Header>
                  <Modal.Body>
                    <div>
                    {t('term_text')}
                    What’s covered in these terms
                    We know it’s tempting to skip these Terms of Service, but it’s important to establish what you can expect from us as you use Google services, and what we expect from you.
                    These Terms of Service reflect the way Google’s business works, the laws that apply to our company, and certain things we’ve always believed to be true. As a result, these Terms of Service help define Google’s relationship with you as you interact with our services. For example, these terms include the following topic headings:

                    What you can expect from us, which describes how we provide and develop our services
                    What we expect from you, which establishes certain rules for using our services
                    Content in Google services, which describes the intellectual property rights to the content you find in our services — whether that content belongs to you, Google, or others
                    In case of problems or disagreements, which describes other legal rights you have, and what to expect in case someone violates these terms
                    Understanding these terms is important because, to use our services, you must accept these terms.

                    Besides these terms, we also publish a Privacy Policy. Although it’s not part of these terms, we encourage you to read it to better understand how you can update, manage, export, and delete your information.
                    </div>      
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose}>{t('close')}</Button>
                  </Modal.Footer>
                </Modal>
                <Button type="submit" variant="contained" fullWidth>{t('submit')}</Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                    {t('link_to_login')} 
                    </Link>
                  </Grid>
                </Grid>
                </Grid>
                <p></p>
                <div className="form-success" >{status ? status.success:''}</div>
                <div className="form-error" >{status ? status.error:''}</div>
              </Form>
      </div>
        )
      }
      </Formik> 
      </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </ThemeProvider>
  );
}


function PageFrontDesign(){

  const { t } = useTranslation();

  return (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
        {t('register')}
     </Typography>
    </Box>
  );
}

function Copyright(props) {

  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {t('copyright')}{" © "}
      <Link color="inherit" href="https://mui.com/">
        Event.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
