import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../Components/AppBar';
import Toolbar from '../Components/ToolBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {Avatar,Menu,MenuItem,IconButton,ListItemIcon} from '@mui/material';
import {Tooltip} from '@mui/material';
import {Logout} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {DashboardContent, IconMenu } from './DashBoard';
import Cookies from 'universal-cookie';

const rightLink = {fontSize: 18, color: 'common.black',ml: 3,};


const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#e0e0e0',
    },
  },
});


function SigninAndRegistry(){
  const { t } = useTranslation();
  return(
    <React.Fragment>
    <Link
       color="inherit"
       variant="h6"
       underline="none"
       href="/login"
       sx={rightLink}
     >
       {t('login')}
     </Link>
     <Link
       variant="h6"
       underline="none"
       href="/register"
       sx={{ ...rightLink }}
     >
       {t('register')}
     </Link>
     </React.Fragment>
  );
}

function AppAppBar(props) {
  const [menuopen, setMenuopen] = React.useState(false);
  const toggleDrawer = () => {
    setMenuopen(!menuopen);
  };
  const m_role = props.m_role;
  //const { t } = useTranslation();
    return (
      <React.Fragment>
         <Box sx={{ display: 'flex' }}>
        <ThemeProvider theme={lightTheme}>
        <AppBar position="fixed" elevation={4} color="primary" enableColorOnDark open={menuopen}>
          <Toolbar sx={{pr: '24px', }}>
          {m_role!== "notSigned"&&<IconMenu open={menuopen} toggleDrawer ={toggleDrawer}/>}
            <Box sx={{ display: 'flex',justifyContent: 'center',}}>
            <Link variant="h6" underline="none" color="inherit" sx={{ fontSize: 24 }} >
              {'Event.io'}
            </Link>
            </Box>
            <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-end' }}>
              {m_role==="notSigned"&& <SigninAndRegistry/>}
              {m_role !== "notSigned"&&<UserAnchor/>}
            </Box>
          </Toolbar>  
        </AppBar>
        </ThemeProvider>
        {m_role !=="notSigned"&&<DashboardContent open={menuopen} toggleDrawer ={toggleDrawer} m_role ={m_role} />}
        </Box>
      </React.Fragment>
    );
  }

  


function UserAnchor(){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.set("view",'notSigned')
    
  };
  return(
    <React.Fragment>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar />
          </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProper}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
           My account
        </MenuItem>
        <MenuItem component ={Link} href ="/" onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}



const menuProper ={
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1
      }
    }
}
  
  export default AppAppBar;