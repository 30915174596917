import React, { useState, useEffect } from "react";
import { Api } from "./Api";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "eventName",
    numeric: false,
    disablePadding: true,
    label: "eventName",
  },
  {
    id: "TicketType",
    numeric: false,
    disablePadding: false,
    label: "ticketType",
  },
  {
    id: "TicketCount",
    numeric: false,
    disablePadding: false,
    label: "ticketCount",
  },
  // {
  //   id: "Name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "name",
  // },
  // {
  //   id: "Max Accomodation",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "max_accomodation",
  // }, 
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (eventName) => {
    onRequestSort(eventName, property);
  };
  const { t } = useTranslation();
  return (
    <TableHead style={{ backgroundColor: "grey" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all bookings",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const navigate = useNavigate();

  return (
    <Toolbar
      variant="dense"
      sx={{
        pl: { sm: 1 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}
 
      {numSelected > 0 ? (
        <div style={{ display: "flex" }}>
          <Tooltip title="Edit">
            <IconButton> 
              <CreateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <Tooltip title="Filter">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export function MyBookings(props) {
  const [mybookingsList, setBookingsList] = useState([]);
  const [search, setSearch] = useState("");

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const cookies = new Cookies();

  const getBookingsList = () => {
    try {
      Api.get("/mybookings/"+cookies.get('uid'))
      // Api.get("/mybookings/"+2)
        .then((response) => {
          setBookingsList(response.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getBookingsList();
  }, []);

  const handleRequestSort = (eventName, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (eventName) => {
    if (eventName.target.checked) {
      const newSelected = mybookingsList.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (eventName, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (eventName, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (eventName) => {
    setRowsPerPage(parseInt(eventName.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (eventName) => {
    setDense(eventName.target.checked);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mybookingsList.length) : 0;

  const { t } = useTranslation();

  return (
    <>
      {/* {
        mybookingsList.filter(item => {
          if(search==="")
          {
            return item;
          }
          else if(item.eventName.toLowerCase().includes(search.toLowerCase()))
          {
             return item;
          }
        }).map(item=>{
          return  (
            <p>{item.eventName}</p>
          );
        })
        } */}
      <PageFrontDesign />
      <br></br>
      <TextField
        id="search"
        label={t("search_here")}
        variant="outlined"
        onChange={(e) => {setSearch(e.target.value)}}
      />
      {/* <input type="text" placeholder="search here" onChange={e=>setSearch(e.target.value)}></input> */}

      <Box
        sx={{
          display: "flex",
          marginTop: 3,
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={mybookingsList.length}
              />
              <TableBody>
                {stableSort(mybookingsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((item) => {
                    if (search === "") {
                      return item;
                    } else if (
                      item.eventName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.ticketType
                        .toLowerCase()
                        .includes(search.toLowerCase()) 
                      // item.organiser.fName
                      //   .toLowerCase()
                      //   .includes(search.toLowerCase()) ||
                      // item.organiser.lName
                      //   .toLowerCase()
                      //   .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(eventName) => handleClick(eventName, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.eventName}
                        </TableCell>
                        <TableCell align="left">{row.ticketType}</TableCell>
                        <TableCell align="left">{row.ticketCount}</TableCell>
                        {/* <TableCell align="left">{row.organiser.fName +" "+ row.organiser.lName}</TableCell> */}
                        {/* <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">
                          {row.max_accomodation}
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={mybookingsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label={t("dense_padding")}
        />
      </Box>
    </>
  );
}
function PageFrontDesign() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("my_bookings")}
      </Typography>
    </Box>
  );
}
