import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Api } from "./Api";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function Copyright(props) {
  const { t } = useTranslation();

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {t("copyright")}
      {" © "}
      <Link color="inherit" href="https://mui.com/">
        Event.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();

export const Login = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleSubmit = (e) => {
    const UserEntry = { email: email, pass: pass };
    console.log(UserEntry);
    Api.post("/login", UserEntry)
      .then((response) => {
        cookies.set("view", response.data.role, { path: "/" , expires:new Date(Date.now()+2592000)});
        cookies.set("uid", response.data.uid, { path: "/" , expires:new Date(Date.now()+2592000)});
        navigate("/" + cookies.get("view"));
      })
      .catch((error) => {
        console.log(error.message);
      });
    e.preventDefault(); // prevent form refresh
    setEmail("");
    setPass("");
  };

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("login")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="youremail@email.com"
              id="email"
              name="email"
              required={true}
              autoFocus
              margin="normal"
              fullWidth
              label={t("email")}
            />
            <TextField
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              type="password"
              placeholder="********"
              id="password"
              name="password"
              autoComplete="on"
              required={true}
              margin="normal"
              fullWidth
              label={t("password")}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={t("remember")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              {t("log in")}
            </Button>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={4}>
                <Link href="#" variant="body2">
                  {t("forgot_password")}?
                </Link>
              </Grid>
              <Grid item xs={8}>
                <Link
                  href="/register"
                  variant="body2"
                  onClick={() => props.onFormSwitch("Register")}
                  className="link-btn"
                >
                  {t("new_account")}
                </Link>
              </Grid>
              <Grid item md={12}>
                <Link href="/" variant="body2">
                  {t("back_to_homepage")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 20, mb: 5 }} />
      </Container>
    </ThemeProvider>
  );
};
