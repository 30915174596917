
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { IconButton } from '@mui/material';

// const Footer = () => {
//   const { i18n } = useTranslation();

//   function changeLanguage(e) {
//     i18n.changeLanguage(e.target.value);
//   }

//   return(
//     <div className='footer'>
//       <Button variant="contained" onClick={changeLanguage} value='en'>English</Button>
//       <Button variant="contained" onClick={changeLanguage} value='sv'>Svenska</Button>
//     </div>
//   )
// }

// export default Footer



const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/ic_flag_en.svg',
  },
  {
    value: 'sv',
    label: 'Svenska',
    icon: '/ic_flag_se.svg',
  },
];

export default function Footer() {

  const [open] = useState(null);
  const { i18n } = useTranslation();
  const handleChangeButton  = (e) => {
    i18n.changeLanguage(e.currentTarget.value);
  };

  return (
    <div className="fixed-bottom">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0vh',
        alignContent: 'flex-end'
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container  maxWidth="sm">
          <Typography variant="body1">
            Language: 
              <IconButton
          onClick={handleChangeButton}
          value={'en'}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
        <img src={LANGS[0].icon} alt={LANGS[0].label} width="55%" height="55%" />
      </IconButton>
      <IconButton
          onClick={handleChangeButton}
          value={'sv'}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
        <img src={LANGS[1].icon} alt={LANGS[1].label} width="55%" height="55%" />
      </IconButton>
      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === LANGS[0].value} onClick={() => handleClose()}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover> */}
   
          </Typography>
          {/* <Copyright /> */}
        </Container>
      </Box>
    </Box>
    </div>
  );
}