import * as React from 'react';
import {styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {IconButton,Toolbar,List,Divider,Box} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AdminListItems, UserListItems } from './ListItems';
import MenuIcon from '@mui/icons-material/Menu';


const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );


export function IconMenu(prop){
    const open = prop.open;
    const toggleDrawer = prop.toggleDrawer;
    return(
      <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-start' }} >
      <IconButton
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{marginRight: '36px',...(open && { display: 'none' }),}}>
        <MenuIcon />
      </IconButton>
      </Box>
    );
  }

export function DashboardContent(prop){
    const open = prop.open;
    const toggleDrawer = prop.toggleDrawer;
    const m_role = prop.m_role;
    return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {m_role==="customer"&&UserListItems}
        {m_role==="admin"&&AdminListItems}
      </List>
    </Drawer>
    );
}