import * as React from 'react';
import AppAppBar from '../View/AppAppBar';
import { Outlet } from "react-router-dom";
import { Box,Container } from '@mui/material';

function AdminPage() {

    return (
      <React.Fragment>
      <AppAppBar m_role = 'admin'/>
      <div className="App">
      </div>
      <Container maxWidth="lg" sx={{ mt: 12, mb: 15 ,display: "flex", flexDirection: "column" ,alignItems:"center" }}>
      <Outlet /> 
      </Container>
      </React.Fragment>
    );
  }

export default AdminPage