import * as React from "react";
import AppAppBar from "../View/AppAppBar";
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

function HomePage() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <AppAppBar m_role="notSigned" />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm="auto"
          md={8}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random/1024x800/?concert)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            mb: 12,
            mt: 8,
          }}
        />
        <Grid item md={4} component={Paper} elevation={7} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 12,
            }}
          >
            <Typography variant="poster">
              <h2>{t("welcome_title")}</h2>
            </Typography>
            <h5>
              {t("welcome_text")}
            </h5>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
export default HomePage;
