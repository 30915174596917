import { Link,Navigate, useRoutes } from 'react-router-dom';
import * as React from 'react';
import { Login } from './Login';
import { Register } from './Register';
import { ListOfEvents } from './ListOfEvents';
import { ForgotPassword } from './ForgotPassword';
import HomePage from './Pages/HomePage';
import AdminPage from './Pages/AdminPage';
import UserPage from './Pages/UserPage';
import { useTranslation } from 'react-i18next';
import { AddEvent } from './AddEvent';
import { EditEvent } from './EditEvent';
import DashApp from './View/DashBoardApp';
import { MyBookings } from './MyBookings';
import { AllBookings } from './AllBookings';

export default function Router() {
    const routes = useRoutes([
      {
        path: '/',
        element: <HomePage/>,
      },
      {
        path: 'login',
        element: <Login/>,
      },
      {
        path: 'forgotPassword',
        element: <ForgotPassword/>,
      },
      {
        path: 'register',
        element: <Register/>,
      },
      {
        path: 'admin',
        element: <AdminPage/>,
        children:[
          { element: <Navigate to="/admin/app" />, index: true },
          { path: 'app', element: <DashApp/> },
          { path: 'addevents', element: <AddEvent/>},
          { path: 'editevents', element: <EditEvent/>},
          { path: 'eventlist', element: <ListOfEvents/> },
          { path: 'bookinglist', element: <AllBookings/> },
        ],
      },
      {
        path: 'customer',
        element: <UserPage />,
        children:[
          { element: <Navigate to="/customer/app" />, index: true },
          { path: 'app', element: <DashApp/> },
          { path: 'bookinglist', element: <MyBookings/> },
        ],
      },
      {
        path: '*',
        element: <NoMatch/>,
      },
    ]);
    return routes;
  }
  

  function NoMatch() {
    const { t } = useTranslation(); // Localisation translation initialization
    return (
      <div>
        <h2>{t('nothing_to_see_here')}</h2>
        <p>
          <Link to="/">{t('go_to_homepage')}</Link>
        </p>
      </div>
    );    
  }