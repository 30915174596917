import * as React from 'react';
import AppAppBar from '../View/AppAppBar';
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Container } from '@mui/material';

const cookies = new Cookies();

function UserPage() {
  //const navigate = useNavigate();
  //if(cookies.get('view') !== "customer"){navigate("/"); }
    return (
      <React.Fragment>
      <AppAppBar m_role = 'customer'/>
      <div className="App">
      </div>
      <Container maxWidth="lg" sx={{ mt: 12, mb: 15 ,display: "flex", flexDirection: "column" ,alignItems:"center" }}>
      <Outlet />
      </Container>
     </React.Fragment>
    );
  }

export default UserPage;