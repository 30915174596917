import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Link from '@mui/material/Link';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditIcon from '@mui/icons-material/Edit';

export const UserListItems = (
    <React.Fragment>
      <ListItemButton component={Link} href ="/customer/app">
        <ListItemIcon>
          <HomeOutlinedIcon/>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton component={Link} href ="/customer/bookinglist">
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="My Booking"  />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
    </React.Fragment>
);

export const AdminListItems = (
    <React.Fragment>
      <ListItemButton component={Link} href ="/admin">
        <ListItemIcon>
          <HomeOutlinedIcon/>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton component={Link} href ="/admin/addevents">
        <ListItemIcon>
          <PostAddOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Add Events" />
      </ListItemButton>
      <ListItemButton component={Link} href ="/admin/eventlist">
        <ListItemIcon>
          <ManageSearchIcon/>
        </ListItemIcon>
        <ListItemText primary="Find Events"/>
      </ListItemButton>
      <ListItemButton component={Link} href ="/admin/editevents">
        <ListItemIcon>
          <EditIcon/>
        </ListItemIcon>
        <ListItemText primary="Edit Events" />
      </ListItemButton>
      <ListItemButton component={Link} href ="/admin/bookinglist">
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="All Bookings" />
      </ListItemButton>
    </React.Fragment>
);